import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { combineReducers } from 'redux';

// slices
import RegisterReducer from './slices/register';

// ----------------------------------------------------------------------

export const createNoopStorage = () => ({
  getItem(_key: string) {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: string) {
    return Promise.resolve();
  },
});

export const storage =
  typeof window !== 'undefined' ? createWebStorage('session') : createNoopStorage();

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['register'],
};

const rootReducer = combineReducers({
  register: RegisterReducer,
});

export default rootReducer;
