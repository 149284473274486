import { Roles } from 'src/enumerate/roles';
import { supabaseClient } from 'src/utils/supabaseClient';

export type Provider = 'twitter' | 'facebook' | 'google';
/**
  Logs in an existing user with Supabase authentication.
  @param {Object} params - The login parameters.
  @param {string} params.email - The user's email address.
  @param {string} params.password - The user's password.
  @returns {Promise<Object>} - The authentication data of the logged-in user.
  @throws {Error} - If an error occurs during the login process.
  */
export async function login({ email, password }: { email: string; password: string }) {
  const { data: user, error } = await supabaseClient.auth.signInWithPassword({
    email,
    password,
  });


  if (error) {
    throw error;
  }
  const { data, error: profilesError } = await supabaseClient
    .from('profiles')
    .select('*')
    .eq('email', email);

  if (profilesError) {
    console.log('error here');
    throw new Error(profilesError.message);
  }
  if (!data || data.length === 0) {
    throw new Error(
      'There is no user with this email, probably you need to register first or you are using the wrong email'
    );
  }
  if (data[0]?.is_activated === false && data[0]?.role !== Roles.ADMIN) {
    throw new Error('Your account is deactivated, please contact the administrator');
  }

  return user;
}

/**
Registers a new user with Supabase authentication.
@param {Object} params - The registration parameters.
@param {string} params.email - The user's email address.
@param {string} params.password - The user's password.
@param {Object} [params.data] - Additional data to be associated with the user.
@returns {Promise<Object>} - The authentication data of the registered user.
@throws {Error} - If an error occurs during the registration process.
*/
export async function register({
  email,
  password,
  data,
}: {
  email: string;
  password: string;
  data?: any;
}) {
  const { data: authData, error: supaBaseError } = await supabaseClient.auth.signUp({
    email,
    password,

    options: {
      data: {
        ...data,
      },
      emailRedirectTo: `${process.env.REACT_APP_RECOVER_PASSWORD_CALLBACK_URL}/auth/jwt/email-verified`,
    },
  });
  if (supaBaseError) {
    throw supaBaseError;
  }
  return authData;
}

/**

  Signs out the currently authenticated user.
  @returns {Promise<void>} - Resolves when the sign out process is complete.
  */
export async function signOut() {
  await supabaseClient.auth.signOut();
}

export async function getSession() {
  const { data, error } = await supabaseClient.auth.getSession();

  if (error) {
    throw error;
  }
  if (!data?.session) {
    throw new Error('No session');
  }
  return data;
}

/**
 * Reset password
 * @param {strign} params - The email where the recover password link code will be sent.
 * @returns
 */
export async function recoverPassword(email: string) {
  const { data, error } = await supabaseClient.auth.resetPasswordForEmail(email, {
    redirectTo: `${process.env.REACT_APP_RECOVER_PASSWORD_CALLBACK_URL}/auth/reset-password/`,
  });
  if (error) {
    throw error;
  }
  return data;
}

export async function updatePassword(password: string) {
  const { data, error } = await supabaseClient.auth.updateUser({
    password,
  });
  if (error) {
    throw error;
  }
  return data;
}

export async function verifySession() {
  const { data, error } = await supabaseClient.auth.getUser();
  const { data: profile, error: profilesError } = await supabaseClient
    .from('profiles')
    .select('*')
    .eq('email', data?.user);

  if (profilesError) {
    throw new Error(profilesError.message);
  }
  if (!profile || profile.length === 0) {
    throw new Error(
      'There is no user with this email, probably you need to register first or you are using the wrong email'
    );
  }
  if (profile[0]?.is_activated === false && profile[0]?.role !== Roles.ADMIN) {
    throw new Error('Your account is deactivated, please contact the administrator');
  }
  if (error) {
    throw error;
  }
  return data;
}

export async function signWithProvider(provider: Provider, redirectTo?: string) {
  const { data, error } = await supabaseClient.auth.signInWithOAuth({
    provider,

    options: {
      redirectTo:
        redirectTo ||
        `${process.env.REACT_APP_RECOVER_PASSWORD_CALLBACK_URL}/dashboard/profile?current=social`,
    },
  });

  if (error) {
    throw error;
  }
  return data;
}

/**
Registers a new user with Supabase authentication.
@param {Object} params - The registration parameters.
@param {string} params.email - The user's email address.
@param {string} params.password - The user's password.
@param {Object} [params.data] - Additional data to be associated with the user.
@returns {Promise<Object>} - The authentication data of the registered user.
@throws {Error} - If an error occurs during the registration process.
*/
export async function AddUserAdmin({
  email,
  password,
  data,
}: {
  email: string;
  password: string;
  data?: any;
}) {
  const { data: authData, error: supaBaseError } = await supabaseClient.auth.admin.createUser({
    email,
    password,
    app_metadata: {
      ...data,
    },
  });
  if (supaBaseError) {
    throw supaBaseError;
  }
  return authData;
}
