import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth

import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { Roles } from 'src/enumerate/roles';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/Dashboard'));
const AddUser = lazy(() => import('src/pages/dashboard/User/AddUser'));
const Users = lazy(() => import('src/pages/dashboard/User/User'));

const Profile = lazy(() => import('src/pages/dashboard/User/profile'));

const Company = lazy(() => import('src/pages/dashboard/company/Company'));
const ActivityLogs = lazy(() => import('src/pages/dashboard/activity-logs/activity-logs'));
const ChangePasswordAfterInvitation = lazy(() => import('src/pages/dashboard/User/ChangePassword'));

const BillingPage = lazy(() => import('src/pages/dashboard/billings/billings'));
const BillingStatsPage = lazy(() => import('src/pages/dashboard/billings-stats/billings-stats'));
const ReviewsStatsPage = lazy(() => import('src/pages/dashboard/reviews-stats/reviews-stats'));
const UsersStatsPage = lazy(() => import('src/pages/dashboard/users-stats/users-stats'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/change-password',
    element: (
      <RoleBasedGuard roles={[Roles.USER]} hasContent sx={{ py: 10 }}>
        <ChangePasswordAfterInvitation />
      </RoleBasedGuard>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'users',
        children: [
          {
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <Users />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'add-user',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <AddUser />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'edit-user/:id',
            element: (
              <RoleBasedGuard hasContent sx={{ py: 10 }}>
                <AddUser isUpdate />
              </RoleBasedGuard>
            ),
            index: true,
          },
        ],
      },

      {
        path: 'company',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <Company />
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'activity-logs',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <ActivityLogs />
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'profile',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <Profile />
          </RoleBasedGuard>
        ),
        index: true,
      },

      {
        path: 'billings',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <BillingPage />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'billings-stats',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <BillingStatsPage />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'reviews-stats',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <ReviewsStatsPage />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'users-stats',
        element: (
          <RoleBasedGuard hasContent sx={{ py: 10 }}>
            <UsersStatsPage />
          </RoleBasedGuard>
        ),
      },
    ],
  },
];
