import { createSlice } from '@reduxjs/toolkit';
import { IUserEmail } from 'src/types/user';

const initialState: IUserEmail = {
  email: null,
};
const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    // SET EMAIL
    setRegistredEmail(state, action) {
      state.email = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setRegistredEmail } = slice.actions;
