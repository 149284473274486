import { supabaseClient } from 'src/utils/supabaseClient';

/**
 * Get profile data
 *
 * @returns {Promise<Object>} - The profile.
 * @throws {Error} - If an error occurs during the login process.
 *
 */
export async function getProfile() {
  const { data: userConnect, error: userConnectError } = await supabaseClient.auth.getUser();
  if (userConnectError) {
    throw new Error(userConnectError.message);
  }
  const { data: userProfile, error: userProfileError } = await supabaseClient
    .from('profiles')
    .select('*')
    .eq('user_id', userConnect?.user.id)
    .single();
  if (userProfileError) {
    throw new Error(userProfileError.message);
  }
  return userProfile;
}
