import { setSession } from 'src/auth/context/jwt/utils';
import { ICompany } from 'src/types/company';
import { supabaseClient } from 'src/utils/supabaseClient';
import { uploadImage } from './wasabi';

/**
 * ADD COMPANY SERVICE
 * @param {object} data
 * @returns {Promise<object>} - Resolves with the company data.
 */
export async function updateCompany(data: ICompany) {
  const { data: userConnect, error: userConnectError } = await supabaseClient.auth.getUser();
  if (userConnectError) {
    throw new Error(userConnectError.message);
  }
  const { data: userCompany, error: userCompanyError } = await supabaseClient
    .from('user_company')
    .select('company_id')
    .eq('user_id', userConnect?.user.id)
    .single();
  if (userCompanyError) {
    throw new Error(userCompanyError.message);
  }

  if (data?.logo_url instanceof File) {
    const url = await uploadImage(data?.logo_url, 'company', userCompany?.company_id);
    data.logo_url = url;
  }

  const { data: companyData, error: supaBaseError } = await supabaseClient
    .from('company')
    .update(data)
    .eq('id', userCompany?.company_id);
  if (supaBaseError) {
    throw new Error(supaBaseError.message);
  }
  return companyData;
}

/**
 *  GET ALL Companies
 * @returns {Promise<object>} - Resolves with the company data Admin.
 *
 */

export async function getAllCompanies() {
  const { data, error: userConnectError } = await supabaseClient.auth.getUser();

  if (userConnectError) {
    throw new Error(userConnectError.message);
  }

  // return supabaseClient.from('company').select('*');
  const { data: companies, error: companiesError } = await supabaseClient
    .from('company')
    .select('*');

  if (companiesError) {
    throw new Error(companiesError.message);
  }

  return companies;
}

/**
 * GET COMPANY SERVICE - Get the company data by userConnect.
 * @returns {Promise<object>} - Resolves with the company data.
 * @throws {Error}
 */
export async function getCompany() {
  const { data: userConnect, error: userConnectError } = await supabaseClient.auth.getUser();
  if (userConnectError) {
    throw new Error(userConnectError.message);
  }
  const { data, error } = await supabaseClient
    .from('company')
    .select('*')
    .eq('user_id', userConnect?.user.id);

  if (error) {
    throw new Error(error.message);
  }
  return data[0];
}

/**
 * GET COMPANY SERVICE - Get the company Mmebers data by company id.
 * @returns {Promise<object>} - Resolves with the company data.
 * @throws {Error}
 * @param {string} companyId
 */
export async function getCompanyMembers(companyId: string) {
  const { data: companyMembers, error: companyMembersError } = await supabaseClient
    .from('user_company')
    .select('user_id')
    .eq('company_id', companyId);
  if (companyMembersError) {
    throw new Error(companyMembersError.message);
  }
  const profileIds = companyMembers?.map((member) => member.user_id);
  const { data: profiles, error: profilesError } = await supabaseClient
    .from('profiles')
    .select('id,name,email,role')
    .in('user_id', profileIds);
  if (profilesError) {
    throw new Error(profilesError.message);
  }

  return profiles;
}

/**
 * Invite Member to Company
 * @param {string} email
 * @param {string} companyId
 * @returns {Promise<object>} - Resolves with the company data.
 */
export async function inviteMember(email: string, companyId: string) {
  const { data: sessions } = await supabaseClient.auth.getSession();
  const { data: verifExist } = await supabaseClient
    .from('profiles')
    .select('id')
    .eq('email', email);
  if (verifExist && verifExist?.length > 0) {
    throw new Error('This email is already used');
  }
  const { error: inviteMemberError } = await supabaseClient.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: `${process.env.REACT_APP_RECOVER_PASSWORD_CALLBACK_URL}dashboard/`,
      data: {
        email,
        name: 'User User',
        role: 'accountOwner',
      },
    },
  });
  if (inviteMemberError) {
    const sessionData = sessions?.session;
    if (!sessionData?.access_token || !sessionData?.refresh_token)
      throw new Error('No session found');
    // REFRESH SESSION
    await supabaseClient.auth.setSession({
      access_token: sessionData?.access_token,
      refresh_token: sessionData?.refresh_token,
    });
    setSession(sessionData?.access_token);
    throw new Error(inviteMemberError.message);
  }
  const sessionData = sessions?.session;
  if (!sessionData?.access_token || !sessionData?.refresh_token)
    throw new Error('No session found');
  // REFRESH SESSION

  await supabaseClient.auth.setSession({
    access_token: sessionData?.access_token,
    refresh_token: sessionData?.refresh_token,
  });
  setSession(sessionData?.access_token);
  const { data: profile, error } = await supabaseClient
    .from('profiles')
    .update({ verifedUser: false })
    .eq('email', email)
    .select('id,user_id')
    .single();
  if (error) {
    throw new Error(error.message);
  }
  const { data: companyData, error: getCompanyError } = await supabaseClient
    .from('user_company')
    .select('company_id')
    .eq('user_id', profile?.user_id)
    .single();
  if (getCompanyError) {
    throw new Error(getCompanyError.message);
  }

  const { error: deleteCompanyError } = await supabaseClient
    .from('company')
    .delete()
    .eq('id', companyData?.company_id);
  if (deleteCompanyError) {
    throw new Error(deleteCompanyError.message);
  }

  const { error: userCompanyError } = await supabaseClient
    .from('user_company')
    .update({ company_id: companyId, role: 1 })
    .eq('user_id', profile?.user_id);
  if (userCompanyError) {
    throw new Error(userCompanyError.message);
  }

  return true;
}
