import Stack from '@mui/material/Stack';
import { Card } from '@mui/material';
import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const settings = useSettingsContext();
  const { themeMode } = settings;

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  return (
    <Stack
      sx={{
        width: 1,

        px: { xs: 2, md: 8 },
        py: { xs: 19, md: 19 },
      }}
    >
      {/*  */}

      <Card
        sx={{
          backgroundColor: themeMode === 'dark' ? 'dark' : 'white',
          width: 1,
          mx: 'auto',
          maxWidth: 480,
        }}
      >
        <Stack
          sx={{
            width: 1,
            mx: 'auto',
            maxWidth: 480,

            px: { xs: 2, md: 8 },
            py: { xs: 10, md: 10 },
          }}
        >
          {children}
        </Stack>
      </Card>
    </Stack>
  );
}
