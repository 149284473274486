import axiosBackendInstance from 'src/utils/axios-backend-instance';

export async function uploadImage(image: File, path: string, fileName: string) {
  try {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('path', path);
    formData.append('fileName', fileName);
    const { data } = await axiosBackendInstance.post('wasabi/upload', formData);
    return data?.data;
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function deleteImage(path: string) {
  try {
    /* put path in body */
    const { data } = await axiosBackendInstance.delete('wasabi/delete', { data: { path } });
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
}
