import axios from 'axios';
// config
import { REST_API_URL } from 'src/config-global';
import { supabaseClient } from './supabaseClient';

// ----------------------------------------------------------------------

const axiosBackendInstance = axios.create({ baseURL: REST_API_URL });

axiosBackendInstance.interceptors.request.use(
  async (config) => {
    const { data, error } = await supabaseClient.auth.getSession();

    const { session } = data;
    if (error) throw new Error(error.message);
    if (session?.access_token) {
      config.headers.Authorization = `Bearer ${session?.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
axiosBackendInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosBackendInstance;
