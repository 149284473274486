import { useEffect, useReducer, useCallback, useMemo } from 'react';

import {
  login as loginWithSupaBase,
  signOut,
  register as registerSupabase,
  getSession,
  recoverPassword as recoverPasswordSupabase,
  updatePassword as updatePasswordSupabaseAuth,
} from 'src/Services/auth';

import { supabaseClient } from 'src/utils/supabaseClient';
import { Roles } from 'src/enumerate/roles';
import { getCompany, updateCompany } from 'src/Services/company';
import { getProfile } from 'src/Services/profile';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.UPDATE_COMPANY]: {
    company: any;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === Types.UPDATE_COMPANY) {
    return {
      ...state,
      user: {
        ...state.user,
        company: action.payload.company,
      },
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      // const company = await getCompany();
      const profile = await getProfile();

      // Attempt to get the user session data from supabaseClient
      const {
        data: { session },
        error: sessionError,
      } = await supabaseClient.auth.getSession();
      if (sessionError) throw new Error(sessionError.message);

      // Extract the access token from the session data
      const access_token_using_provider = session?.access_token;

      // Check if the access token is valid and set the session if it is
      if (access_token_using_provider && isValidToken(access_token_using_provider)) {
        setSession(access_token_using_provider);

        // Retrieve user information using the access token
        const {
          data: { user },
        } = await supabaseClient.auth.getUser(access_token_using_provider);
        const finaleUser = {
          ...user,
          // company,
          profile,
        };
        // Dispatch user data to the application state
        dispatch({
          type: Types.INITIAL,
          payload: { user: finaleUser },
        });
      } else {
        // If no valid access token from the provider, check session storage
        const accessToken = sessionStorage.getItem(STORAGE_KEY);

        // If a valid access token is found in session storage, set the session
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          // Retrieve user information using the access token
          const {
            data: { user },
          } = await supabaseClient.auth.getUser(accessToken);

          const finaleUser = {
            ...user,
            // company,
            profile,
          };
          // Dispatch user data to the application state
          dispatch({
            type: Types.INITIAL,
            payload: { user: finaleUser },
          });
        } else {
          // If no valid access token found, set the user to null
          dispatch({
            type: Types.INITIAL,
            payload: { user: null },
          });
        }
      }
    } catch (error) {
      // Handle any errors that occur during the process
      console.error(error);

      // Set the user to null in case of an error
      dispatch({
        type: Types.INITIAL,
        payload: { user: null },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const data = {
      email,
      password,
    };
    const { user, session } = await loginWithSupaBase(data);

    // const company = await getCompany();
    const profile = await getProfile();
    const finaleUser = {
      ...user,
      // company,
      profile,
    };
    setSession(session.access_token);
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: finaleUser,
      },
    });
  }, []);
  const UpdateCompany = useCallback(async (data: any) => {
    const company_data = await updateCompany(data);

    dispatch({
      type: Types.UPDATE_COMPANY,
      payload: {
        company: company_data,
      },
    });
  }, []);

  const LoginWithSession = useCallback(async () => {
    try {
      const { session } = await getSession();
      setSession(session?.access_token);
      dispatch({
        type: Types.LOGIN,
        payload: {
          user: session?.user,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      company_name: string
    ) => {
      await registerSupabase({
        email,
        password,
        data: {
          name: `${firstName} ${lastName}`,
          role: Roles.USER,
          company_name,
        },
      });
    },
    []
  );
  // RECOVER LINK FOR REST PASSWORD
  const recover = useCallback(async (email: string) => {
    await recoverPasswordSupabase(email);
  }, []);

  // RECOVER LINK FOR REST PASSWORD
  const reset = useCallback(async (password: string) => {
    await updatePasswordSupabaseAuth(password);
  }, []);
  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    await signOut();
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      LoginWithSession,
      UpdateCompany,
      register,
      recover,
      reset,
      logout,
    }),
    [login, logout, register, reset, LoginWithSession, UpdateCompany, recover, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
