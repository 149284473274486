// ----------------------------------------------------------------------

const ROOTS = {
  ROOT: '/',
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
};

// ----------------------------------------------------------------------

export const paths = {
  changePassword: `${ROOTS.ROOT}change-password`,
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      recoverPassword: `${ROOTS.AUTH}/jwt/recover-password`,
      resetPassword: `${ROOTS.AUTH}/jwt/reset-password`,
    },
    verify: {
      root: `${ROOTS.AUTH}/verify`,
      emailVerified: `${ROOTS.AUTH}/jwt/email-verified`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      add: `${ROOTS.DASHBOARD}/users/add-user`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/users/edit-user/${id}`,
    },
    reviews: {
      root: `${ROOTS.DASHBOARD}/reviews`,
    },

    activityLogs: {
      root: `${ROOTS.DASHBOARD}/activity-logs`,
    },

    billings: {
      root: `${ROOTS.DASHBOARD}/billings`,
    },

    usersStats: {
      root: `${ROOTS.DASHBOARD}/users-stats`,
    },
    reviewsStats: {
      root: `${ROOTS.DASHBOARD}/reviews-stats`,
    },
    billingsStats: {
      root: `${ROOTS.DASHBOARD}/billings-stats`,
    },
  },

  settings: {
    root: ROOTS.SETTINGS,
  },
};
