import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components

import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

const icon = (iconClass: string) => <Iconify icon={`${iconClass}`} />;

const ICONS = {
  dashboard: icon('clarity:dashboard-solid'),
  user: icon('majesticons:users-line'),
  list: icon('material-symbols:list'),
  add: icon('icon-park-outline:add'),
  company: icon('mdi:company'),
  logs: icon('solar:document-linear'),
  system: icon('tdesign:system-setting'),
  billing: icon(
    'streamline:money-cash-dollar-coin-accounting-billing-payment-cash-coin-currency-money-finance'
  ),

  userStats: icon('gis:map-users'),
  reviewsStats: icon('gridicons:stats'),
  billingStats: icon('icomoon-free:stats-dots'),
  // listingsStats: icon('carbon:inventory-management'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Review',
        items: [{ title: 'Dashboard', path: paths.dashboard.root, icon: ICONS.dashboard }],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: 'users',
            path: paths.dashboard.users.root,
            icon: ICONS.user,
            children: [
              { title: 'List', path: paths.dashboard.users.root },
              {
                title: 'Add',
                path: paths.dashboard.users.add,
              },
            ],
          },

          {
            title: 'Billings',
            path: paths.dashboard.billings.root,
            icon: ICONS.billing,
          },

          {
            title: 'Activity Logs',
            path: paths.dashboard.activityLogs.root,
            icon: ICONS.logs,
          },

          {
            title: 'System Logs',
            path: paths.dashboard.billingsStats.root,
            icon: ICONS.system,
          },
        ],
      },

      {
        subheader: 'statistics',
        items: [
          {
            title: 'users Statistics',
            path: paths.dashboard.usersStats.root,
            icon: ICONS.userStats,
          },

          {
            title: 'Reviews statistics',
            path: paths.dashboard.reviewsStats.root,
            icon: ICONS.reviewsStats,
          },

          {
            title: 'Billings Statistics',
            path: paths.dashboard.billingsStats.root,
            icon: ICONS.billingStats,
          },
        ],
      },
    ],
    []
  );

  return data;
}
